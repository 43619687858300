.table-wrapper {
    overflow-x: auto;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.input-group {
    display: flex;
    align-items: center;
}

.price-input {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    flex: 1;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 70px;
}

.price-input:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.price-input.editable {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button-group {
    display: flex;
    flex-direction: column;
}

.button-group button {
    margin-bottom: 0.5rem;
}

.btn {
    margin-right: 0.5rem;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}
