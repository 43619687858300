@keyframes progress-bar {
    0% { left: -5vw; }
    100% { left: 100%;}
}
.loader-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; /* Choose your background color */
}
#loader {
    max-width: 128px;
    position: relative;
}
#logo {
    width: 94px;
    margin: 0 auto;
    padding-bottom: 35px;
    text-align: center; /* Center the logo */
}
#progressbar {
    background-color: #fe4e2f;
    height: 3px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}
#progressbar::before {
    width: 5vw;
    position: absolute;
    left: -5vw;
    background-color: #ffa500;
    content: ' ';
    display: block;
    height: 3px;
    border-radius: 30px;
    animation: progress-bar 1.5s infinite alternate ease-in-out;
}

.e-albania {
    height: 100px; /* Adjust height as needed */
    position: relative;
    width: 100px; /* Adjust width as needed */

    svg:not(#orbit) {
        animation: logo_kinetics 2.5s linear infinite normal forwards;
        position: absolute;
        top: -50%;
        left: 0;
        transform-origin: bottom center;
        max-width: 100%;
    }
}

.logo-title {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: normal;
    color: #fff;
    font-size: 22px;
    margin-top: 4px;
    margin-bottom: 4px;
}

@keyframes logo_kinetics {
    to {
        transform: rotate(360deg);
    }
}