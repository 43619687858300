/* Main Container Styling */
.container-fluid {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

/* Table Wrapper for Scrolling */
.table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    margin: 20px auto;
    position: relative; /* Enables scroll button positioning */
}

/* Enhanced Table Styling with Glass Effect */
.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 15px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.4);  /* Glass-like effect */
    backdrop-filter: blur(10px);           /* Applying blur for glass effect */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Table Data Styling with Enhanced Glass Effect */
.table td {
    padding: 16px 12px;
    font-size: 0.9rem;
    color: #555;
    border-bottom: 1px solid rgba(224, 224, 224, 0.3);
    background: rgba(255, 255, 255, 0.3);   /* Semi-transparent for glass effect */
    backdrop-filter: blur(10px);             /* Applying blur for glass effect */
    transition: background-color 0.4s ease, transform 0.3s ease;
}

/* Header Styling with Glass Effect */
.table th {
    background: rgba(255, 81, 0, 0.8);      /* Slight transparency to mimic glass */
    color: #fff;
    font-weight: bold;
    padding: 20px 15px;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.95rem;
    border-bottom: 2px solid rgba(129, 196, 8, 0.3);
    white-space: nowrap;
    backdrop-filter: blur(10px);             /* Applying blur for glass effect */
}


/* Row Hover Effect */
.table tr:hover td {
    background-color: rgba(244, 76, 0, 0.08);
    transform: scale(1.02);
}

/* Icons for Headers and Actions */
.table th i,
.table td i {
    margin-right: 8px;
    color: #f44c00;
}

.table-scroll {
    overflow-x: auto;
    padding-bottom: 15px;
    scrollbar-width: thin;
}

/* Scroll Button with Fixed Positioning */
.scroll-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.scroll-btn {
    background-color: #f44c00;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.2em;
    padding: 5px 10px;
    border-radius: 6px;
    transition: transform 0.3s ease;
}

.left-arrow {
    margin-right: 5px;
}

.right-arrow {
    margin-left: 5px;
}

/* Arrow Button Hover Effect */
.scroll-btn:hover {
    transform: scale(1.1);
}

/* Responsive Adjustments for Scroll Button */
@media (max-width: 600px) {
    .scroll-btn {
        width: 12%;
        /* height: 1.5rem; */
        font-size: 1rem;
    }
}

/* Status Color Classes for Clear Visuals */
.status-pending {
    color: #ff9800 !important;
    font-weight: bold;
}

.status-delivered {
    color: #4caf50 !important;
    font-weight: bold;
}

.status-transit {
    color: #2196f3 !important;
    font-weight: bold;
}

.status-cancelled {
    color: #f44336 !important;
    font-weight: bold;
}

/* Button Styles for Actions */
.btn {
    padding: 8px 12px;
    font-size: 0.85rem;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-primary {
    background-color: #f44c00;
    color: #fff;
}

.btn-primary:hover {
    background-color: #62aa12;
    transform: translateY(-3px);
}

/* Responsive Table Adjustments */
@media screen and (max-width: 768px) {
    .table th,
    .table td {
        font-size: 0.85rem;
        padding: 10px;
    }

    .btn {
        font-size: 0.8rem;
    }
}

/* Dashboard Card with Enhanced Background Image */
.dashboard-card {
    background: url('../../Images/3601229.jpg') center/cover no-repeat;
    border-radius: 15px;
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

/* Glass Content Overlay with Enhanced Frosted Glass Effect */
.glass-content {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    padding: 20px;
    width: 85%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.3);
}

/* Icon Wrapper with Enhanced Glass Effect and Glow */
.icon-wrapper {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.25);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

/* Icon Styling with Glow Effect */
.icon {
    color: #f44c00;
    font-size: 2.5rem;
    transition: transform 0.4s ease, color 0.4s ease;
}

/* Hover Effects for a Dynamic Look */
.dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 14px 35px rgba(0, 0, 0, 0.5);
}

.dashboard-card:hover .glass-content {
    transform: scale(1.07);
}

.dashboard-card:hover .icon-wrapper {
    transform: scale(1.2);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 0 10px rgba(255, 255, 255, 0.2);
}

.dashboard-card:hover .icon {
    color: #ff5722;
    transform: scale(1.15);
}

/* Typography with Better Visibility */
.dashboard-card p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
}

.dashboard-card h6 {
    font-size: 22px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.95);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .glass-content {
        flex-direction: column;
        text-align: center;
    }
    .icon {
        font-size: 2rem;
    }
    .dashboard-card h6 {
        font-size: 18px;
    }
    .dashboard-card p {
        font-size: 12px;
    }
}

@media screen and (max-width: 480px) {
    .dashboard-card {
        flex-direction: column;
        align-items: center;
    }

    .glass-content {
        padding: 10px;
    }

    .dashboard-card h6 {
        font-size: 16px;
    }

    .dashboard-card p {
        font-size: 11px;
    }
}


/* Glitters effect Start */
.glitter-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
    z-index: 9999;
}

.glitter {
    position: absolute;
    top: -100px;
    width: 8px;
    height: 8px;
    background: radial-gradient(circle, #fff, rgba(255, 255, 255, 0));
    border-radius: 50%;
    opacity: 0.8;
    animation: fall linear infinite;
    transform-origin: center;
}

/* Different glitter colors */
.glitter:nth-child(odd) {
    background: radial-gradient(circle, #ffeb3b, rgba(255, 255, 255, 0)); /* Yellow */
    animation-duration: 4s;
}
.glitter:nth-child(even) {
    background: radial-gradient(circle, #f44c00, rgba(255, 255, 255, 0)); /* Orange */
    animation-duration: 5s;
}
.glitter:nth-child(3n) {
    background: radial-gradient(circle, #00bcd4, rgba(255, 255, 255, 0)); /* Cyan */
    animation-duration: 6s;
}
.glitter:nth-child(4n) {
    background: radial-gradient(circle, #e91e63, rgba(255, 255, 255, 0)); /* Pink */
    animation-duration: 4.5s;
}
.glitter:nth-child(5n) {
    background: radial-gradient(circle, #8bc34a, rgba(255, 255, 255, 0)); /* Green */
    animation-duration: 3.5s;
}

@keyframes fall {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh) rotate(720deg);
        opacity: 0;
    }
}
/* Glitters effect end */

a.show-all {
    background-color: #f44c00;
    border-color: #f44c00;
    padding: 14px 28px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 30px;
    transition: background-color 0.3s, border-color 0.3s;
    display: inline-block;
    color: #fff
}
a.show-all:hover {
    background-color: #d43f00;
    border-color: #d43f00;
    color: #fff;
}

a.show-all:focus {
    outline: none;
}
.number-rcords {
    color: #d43f00;
}